* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: rgba(248, 248, 250, 1);
  overflow-x: hidden;
}

.circle {
  position: absolute;
  background: radial-gradient(
      79% 79% at 65.5% 50%,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    radial-gradient(95.83% 280.23% at 0% 6.38%, #516ff4 0%, #8355f5 81.5%),
    linear-gradient(0deg, #52b6ff, #52b6ff);
  z-index: -1;
  border-radius: 50%;
  opacity: 0.2;
}

.rectangle {
  position: absolute;
  background: radial-gradient(
      79% 79% at 65.5% 50%,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    radial-gradient(95.83% 280.23% at 0% 6.38%, #516ff4 0%, #8355f5 81.5%),
    linear-gradient(0deg, #52b6ff, #52b6ff);
  z-index: -1;
  border-radius: 10px;
  opacity: 0.2;
}

.circle-1 {
  top: -35%;
  right: -10%;
  width: 35vw;
  height: 35vw;
}

.circle-2 {
  top: 1%;
  right: 26%;
  width: 6vw;
  height: 6vw;
}

.circle-3 {
  top: 10%;
  left: -8%;
  width: 15vw;
  height: 15vw;
}

.circle-4 {
  top: 30%;
  left: 10%;
  width: 23vw;
  height: 23vw;
}

.circle-5 {
  left: 32%;
  top: 65%;
  width: 6vw;
  height: 6vw;
}

.circle-6 {
  bottom: 15%;
  right: 0%;
  width: 10vw;
  height: 10vw;
}

@media screen and (max-device-width: 480px) {
  .circle-2 {
    top: -10%;
    right: 0%;
    width: 50vw;
    height: 50vw;
  }

  .circle-3 {
    left: -15%;
    width: 30vw;
    height: 30vw;
  }

  .circle-4 {
    left: 4%;
    width: 50vw;
    height: 50vw;
  }

  .circle-5 {
    left: 50%;
    top: 51%;
    width: 10vw;
    height: 10vw;
  }

  .circle-6 {
    bottom: 6%;
    width: 25vw;
    height: 25vw;
  }
}
