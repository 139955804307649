.feedback-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  background-color: #dcb0fc;
  background-image: linear-gradient(to bottom right, #b880ff, #ac85ff, #8196ff);
}

.feedback {
  color: white;
  min-width: 350px;
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.feedback h1 {
  font-size: 2.5rem;
}

.feedback-helper-text {
  margin-left: 3px;
  font-weight: 400;
  min-width: 300px;
}

.feedback-submit {
  font-size: 16px;
  border: none;
  width: 100px;
  height: 40px;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  background-image: linear-gradient(to right, #b880ff, #ac85ff, #8196ff);
}

.feedback-submit.sent {
  border: none;
  width: 100px;
  height: 40px;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  background: green;
}

.feedback-submitted-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.feedback-sent-remark {
  color: black;
  font-weight: 600;
}

.choosen-idea {
  color: rgb(78, 78, 78);
  min-width: 300px;
  text-align: left;
  list-style: none;
}

.choosen-idea label{
  font-weight:500;
}

.choosen-idea li{
  margin-bottom:8px;
}

@media screen and (max-device-width: 540px) {

  .choosen-idea{
    margin:0;
    width:100%;
    margin-bottom: 10px;
    font-size: 13px;
  }
 
  .feedback {
    max-width: 350px;
  }
}



