.sloganCraft-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}

.keyboard-container {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  max-width: 800px;
  z-index: 10;
}

.custom-keyboard {
  font-size: 16px;
}

.custom-keyboard .hg-button {
  height: 15px;
  min-width: 30px;
  padding: 5px;
}

/* ----------NAVBAR----------------- */

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.navbar-logo {
  height: 40px;
  cursor: pointer;
}

.nav-input-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.submit-button {
  border: none;
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 7.5px;
  color: white;
  font-size: 1em;
  font-weight: bolder;
  gap: 10px;
  cursor: pointer;
  background: linear-gradient(90deg, #3e78f3 0%, #944df6 100%),
    radial-gradient(
      145.71% 592.18% at 145.71% -61.11%,
      #e94a9a 12%,
      rgba(233, 74, 154, 0.444) 43.05%,
      rgba(103, 45, 255, 0) 58.5%,
      #672de1 100%
    ),
    radial-gradient(
      79% 79% at 65.5% 50%,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(0deg, #52b6ff, #52b6ff);
}

.nav-input-container input {
  width: 300px;
  padding: 10px 50px 10px 10px;
  font-size: 0.85em;
  border: 1px solid #c8c0e0;
  border-radius: 5px;
  outline: none;
}

.nav-container input:focus {
  border: 1.5px solid #692cf3;
}

.microphone-icon {
  position: absolute;
  right: 10px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.microphone-icon.listening {
  filter: brightness(0) saturate(100%) invert(15%) sepia(100%) saturate(7500%)
    hue-rotate(356deg) brightness(100%) contrast(101%);
}

.generate-icon {
  width: 24px;
  height: 24px;
}

@media (max-width: 600px) {
  .navbar-input {
    width: 100px;
  }
}

/* ----------------BODY---------------- */

.body {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
}

.body.showKeyboard {
  align-items: center;
}

.slogan-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 70px;
  margin-top: 140px;
}

.slogan-container.showKeyboard {
  gap: 70px;
  margin-top: 80px;
}

.slogan {
  color: #000;
  font-size: 3em;
  margin-bottom: 20px;
}

.subtext {
  color: #666;
  font-size: 2em;
  margin-bottom: 30px;
}
.slogan-footer-link {
  color: #874ff4;
  font-size: 1.2em;
  font-weight: 500;
}

.highlight {
  color: white;
  padding: 5px;
  background-color: #874ff4;
}

.welcome-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 60px;
  margin-top: 217px;
}

.welcome-container.showKeyboard {
  gap: 60px;
  margin-top: 0px;
}

.hero-title {
  color: #874ff4;
  font-size: 3em;
}

.hero-subtitle {
  font-size: 1.5em;
  color: rgba(60, 59, 73, 1);
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.submit-button {
  border: none;
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 7.5px;
  color: white;
  font-size: 1em;
  font-weight: bolder;
  gap: 10px;
  cursor: pointer;
  background: linear-gradient(90deg, #3e78f3 0%, #944df6 100%),
    radial-gradient(
      145.71% 592.18% at 145.71% -61.11%,
      #e94a9a 12%,
      rgba(233, 74, 154, 0.444) 43.05%,
      rgba(103, 45, 255, 0) 58.5%,
      #672de1 100%
    ),
    radial-gradient(
      79% 79% at 65.5% 50%,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(0deg, #52b6ff, #52b6ff);
}

.input-container input {
  width: 500px;
  padding: 15px 50px 15px 15px;
  font-size: 1em;
  border: 1px solid #c8c0e0;
  border-radius: 5px;
  outline: none;
}

.input-container input:focus {
  border: 1.5px solid #692cf3;
}

#contentDiv {
  font-size: 3em;
  font-weight: 700;
}

#contentDiv span {
  color: white;
  background-color: #874ff4;
}

.microphone-icon {
  position: absolute;
  right: 10px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.microphone-icon.listening {
  filter: brightness(0) saturate(100%) invert(15%) sepia(100%) saturate(7500%)
    hue-rotate(356deg) brightness(100%) contrast(101%);
}

.generate-icon {
  width: 24px;
  height: 24px;
}

@media (max-width: 600px) {
  .navbar-input {
    width: 100px;
  }

  .body {
    flex: none;
    margin-top: 20px;
  }
}

/* ------------------FOOTER------------------- */

.footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 20px;
}

.footer.centered {
  justify-content: center;
}

.footer-text {
  color: #000;
  font-size: 1.3em;
  font-weight: 500;
}

.restart {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.previous-icon {
  width: 22px;
  height: 22px;
}

.qr-code-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
}

.qr-code {
  width: 200px;
  height: 200px;
  cursor: pointer;
}

.qr-text {
  font-size: 1.5em;
  font-weight: 700;
  font-family: system-ui;
  color: #692cf3;
  text-align: center;
}

/* --------MOBILE------------ */

@media screen and (max-device-width: 540px) {
  .modal-input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  .modal-input-container input {
    width: 300px;
    padding: 10px 50px 10px 10px;
    font-size: 0.85em;
    border: 1px solid #c8c0e0;
    border-radius: 5px;
    outline: none;
  }

  .modal-input-container input:focus {
    border: 1.5px solid #692cf3;
  }

  .nav-input-desktop {
    display: none;
  }
  .nav-input-container {
    flex-direction: column;
    width: 100%;
    display: none;
  }

  .generate-icon-button {
    border-radius: 5px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90deg, #3e78f3 0%, #944df6 100%),
      radial-gradient(
        145.71% 592.18% at 145.71% -61.11%,
        #e94a9a 12%,
        rgba(233, 74, 154, 0.444) 43.05%,
        rgba(103, 45, 255, 0) 58.5%,
        #672de1 100%
      ),
      radial-gradient(
        79% 79% at 65.5% 50%,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      linear-gradient(0deg, #52b6ff, #52b6ff);
  }

  .generate-icon-button .generate-icon {
    width: 30px;
    height: 30px;
  }

  .nav-input-container input {
    width: 350px;
  }

  .input-container input {
    width: 350px;
  }

  .slogan-container {
    margin-top: 40px;
  }

  .hero-title {
    font-size: 2.5em;
  }

  .hero-subtitle {
    font-size: 1.25em;
  }

  .slogan {
    font-size: 2.5em;
  }

  .subtext {
    font-size: 1.25em;
  }

  .slogan-footer-link {
    font-size: 1em;
  }

  .footer {
    flex-direction: column-reverse;
    align-items: center;
    gap: 20px;
  }

  .footer .restart {
    position: absolute;
    top: 100px;
    left: 20px;
  }
}
