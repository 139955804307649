.ad-page {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh; 
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black; 
    z-index: 100; 
  }
  
  .full-screen-gif {
    max-width: 100%;
    max-height: 100%; 
    object-fit: cover;  
  }
  
  
  